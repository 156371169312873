import './FilledButton.css';

const FilledButton = ({ txt, href }) => {
    return (
        <div className='overflow-wrapper'>
            <a className='btn filled' href={href}>
                <div className='fill-element'></div>
                <div className='btn-text'>{txt}</div>
            </a>
        </div>
    )
}

export default FilledButton;