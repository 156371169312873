import React from 'react';
import './VideoBackground.css';

const VideoBackground = ({ src }) => {
    return (
        <div className='embed-wrapper'>
            <iframe
                title='Blackhole'
                src={src}
                allowFullScreen>
            </iframe>
        </div>
    )
}

export default VideoBackground;