import './App.css';
import './index.css'
import './locomotive-scroll.css';

import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import locomotiveScroll from "locomotive-scroll";

import { PreviewContainer } from './containers';
import { Home, Work, About, Contact, Error404, ComingSoon } from './pages';

function App() {
  const [activeIndex, setActiveIndex] = useState(-1);

  /*
  useEffect(() => {
    // eslint-disable-next-line
    const scroll = new locomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true
    });
  }, []);
  */

  // data-scroll data-scroll-speed="-0.5" data-scroll-position="top"

  /*
    <Route exact path="/" element={<Home setActiveIndex={setActiveIndex} />} />
    <Route exact path="/about" element={<About />} />
    <Route exact path="/work" element={<Work setActiveIndex={setActiveIndex} />} />
    <Route exact path="/contact" element={<Contact />} />
    <Route path="*" element={<Error404 />} />
  */

  return (
    <Router>
      <PreviewContainer activeIndex={activeIndex} />
      <div data-scroll-container data-scroll-speed='0.1'>
        <div data-scroll-section>
          <Routes>
            <Route exact path="/" element={<ComingSoon />} />
            <Route exact path="*" element={<ComingSoon />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;