import './ComingSoon.css';

import { FilledButton } from '../../components';

function ComingSoon() {
    return (
        <section>
            <div className='flex-col coming-soon'>
                <h1>Under Construction</h1>
                <h3>Come Back Later</h3>
                <div className='flex-row action-bar'>
                    <div className='flatbar thin' />
                    <FilledButton txt={'INQUIRE'} href={'mailto: contact@scottanderson.uk'} />
                </div>
            </div>
        </section>
    );
}

export default ComingSoon;