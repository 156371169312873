import './PreviewContainer.css';
import '../../index.css';
import ProjectData from '../../assets/data/ProjectData';

import { gsap } from 'gsap';
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(useGSAP);

const PreviewContainer = ({ activeIndex }) => {
    var active = activeIndex >= 0;

    useGSAP(() => {
        var posX = 0;
        var posY = 0;
        var mouseX = 0;
        var mouseY = 0;

        gsap.to(".preview-container", {
            duration: 0.0083333333,
            repeat: -1,
            onRepeat: function () {
                posX += (mouseX - posX) / 15;
                posY += (mouseY - posY) / 15;
                gsap.set(".preview-container", {
                    css: {
                        left: posX - 12,
                        top: posY - 12
                    }
                });
            }
        });

        /*
        gsap.to(".image-box", {
            repeatRefresh: true,
            scale: () => active ? 1 : 0,
            duration: 0.2,
            ease: 'power4.inOut',
            repeat: -1,
        });
        */

        window.addEventListener("mousemove", e => {
            mouseX = e.clientX;
            mouseY = e.clientY;
        });

    }); //{dependencies: [active, activeIndex], scope: container}

    var lastIndex = Math.abs(activeIndex + 1);
    if (!active) {
        activeIndex = lastIndex;
    }

    return (
        <div className='preview-container'>
            <div className='flex-col image-box' style={{ transform: `scale(${active ? 100 : 0}%)` }}>
                {ProjectData.map(({ thumb }, index) =>
                    <img src={thumb} alt='Preview_Thumb' key={index}
                        style={{
                            transform: `translateY(calc(${activeIndex * -100}% + 1px))`
                        }} />
                )}
            </div>
        </div>
    );
};

export default PreviewContainer;