import './Icon.css';
import { Size } from '../../constants';

const Icon = ({ icon, size }) => {
    let className;
    switch (size) {
        case Size.large:
            className = 'icon large';
            break;
        case Size.medium:
            className = 'icon medium';
            break;
        case Size.small:
            className = 'icon small';
            break;
        default:
            className = 'icon';
    }

    return (
        <div className={className} style={{ backgroundImage: `url(${icon})`}} />
    )
}

export default Icon;