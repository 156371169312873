const data = [
    {
        title: 'Black Hole Simulation',
        tags: 'Simulation',
        desc: 'Raymarched black hole using HLSL',
        href: '/',
        thumb: require('../images/thumb_BlackHole.jpg')
    },
    {
        title: 'Delaunay Triangulation',
        tags: 'Algorithms, Utility',
        desc: 'Delaunay Triangulation using Bowyer-Watson',
        href: '/',
        thumb: require('../images/thumb_Delaunay.jpg')
    },
    {
        title: 'Procedural Topography',
        tags: 'Algorithms, Utility',
        desc: 'Topographic map generator',
        href: '/',
        thumb: require('../images/thumb_Topography.jpg')
    }, {
        title: 'Keplerian Orbits',
        tags: 'Simulation, Algorithms',
        desc: 'Solar system simulation',
        href: '/',
        thumb: require('../images/thumb_SolarSystemSim.jpg')
    },
    {
        title: 'Accurate Starfield',
        tags: 'Algorithms, Utility',
        desc: 'Accurate starfield generator',
        href: '/',
        thumb: require('../images/thumb_Starfield.jpg')
    },
    {
        title: 'Website Development',
        tags: 'Web',
        desc: 'Professional portfolio website development',
        href: '/',
        thumb: require('../images/thumb_JHwebsite.jpg')
    },
]

export default data;